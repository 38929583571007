var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"admin-settings",attrs:{"title":"Innstillinger for prosjekt","subtitle":"Her får du en oversikt over diverse innstillinger du kan foreta deg","variant":"prompt"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(!_vm.payload.hasProject)?[_c('div',{staticClass:"bold"},[_vm._v("Ønsker du å bestille/oppgradere til fullverdig prosjektside?")]),_c('Button',{staticClass:"mt-2 mb-1",attrs:{"label":"Ja, oppgrader til fullverdig prosjektside","theme":"primary","icon":"fa-pro-solid:arrow-up-right-from-square"},on:{"click":function($event){{
            _vm.$emit('reset')
            _vm.$emit('navigate', 'order')
          }}}}),_c('Alert',{staticClass:"mb-4",attrs:{"iconPlacement":"left"}},[[_c('div',[_vm._v("Kommer-for-salg siden vil nå erstattes av en fullverdig prosjektside.")])]],2)]:_vm._e(),(_vm.payload.flatfinderEditUrl)?[_c('div',{staticClass:"bold"},[_vm._v("Tilpass eller opprett boligvelger i Kvass")]),_c('Button',{staticClass:"mt-2 mb-4",attrs:{"label":"Boligvelger i Kvass","theme":"primary","icon":"fa-pro-solid:arrow-up-right-from-square","tag":"a","target":"_blank","href":_vm.payload.flatfinderEditUrl,"disabled":_vm.payload.project.archived}})]:_vm._e(),_c('div',{staticClass:"bold"},[_vm._v(_vm._s(_vm.publishLabel))]),_c('RadioSegment',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.hasPublished ? 'For å avpublisere prosjektet, ta kontakt med support@kvass.no' : '',
        placement: 'right',
        delay: 0,
        offet: 20,
        classes: ['core-broker-step-products__tooltip'],
      }),expression:"{\n        content: hasPublished ? 'For å avpublisere prosjektet, ta kontakt med support@kvass.no' : '',\n        placement: 'right',\n        delay: 0,\n        offet: 20,\n        classes: ['core-broker-step-products__tooltip'],\n      }"}],staticClass:"mt-2 mb-1",attrs:{"disabled":_vm.hasPublished || _vm.payload.project.archived || (!_vm.payload.hasProject && _vm.payload.upcoming.archived),"promise":_vm.promise,"items":_vm.items},on:{"input":_vm.Publish,"onSuccess":function () { return (_vm.hasPublished = true); },"onError":function () { return (_vm.publish = false); }},model:{value:(_vm.publish),callback:function ($$v) {_vm.publish=$$v},expression:"publish"}}),_c('Alert',{staticClass:"admin-settings__publish-notification",attrs:{"iconPlacement":"left"}},[[_c('div',{staticClass:"bold"},[_vm._v(" Prosjektet blir nå publisert på "),_c('a',{staticClass:"bold",attrs:{"target":"_blank","href":_vm.payload.homepage.url}},[_vm._v(_vm._s(_vm.payload.homepage.label))]),_vm._v(" nettside. ")]),_c('div',[_vm._v("Publisering påvirker ikke")]),_c('ul',{staticClass:"mt-0"},[_c('li',[_vm._v(" Finn annonse for prosjektet ")]),_c('li',[_vm._v(" Annet annonseringsmateriell ")])])]],2),(_vm.payload.upcoming.published && _vm.payload.hasProject)?_c('Alert',{staticClass:"mt-2",attrs:{"iconPlacement":"left"}},[[_c('div',[_vm._v("Kommer-for-salg siden er fortsatt publisert frem til fullverdig prosjektside er lansert.")])]],2):_vm._e(),(_vm.payload.project.preview || _vm.payload.upcoming.preview)?[_c('Button',{staticClass:"mt-4",attrs:{"label":_vm.payload.hasProject ? 'Synkroniser prosjektside' : 'Synkroniser kommer for salg side',"icon":"fa-pro-solid:rotate","theme":"primary","disabled":_vm.payload.hasProject ? _vm.payload.project.archived : _vm.payload.upcoming.archived,"promise":_vm.updatePromise,"labels":{
          success: 'Synkronisert',
          loading: 'Synkroniserer',
          error: 'Noe gikk galt',
        }},on:{"click":_vm.Update}}),_c('Alert',{staticClass:"admin-settings__update-notification mt-2",attrs:{"iconPlacement":"left"}},[[_c('div',[_vm._v(" Fagsystemet synkroniseres én gang i døgnet automatisk, og når fagsystemet sender push varsler mot Kvass. Du kan også synkronisere nettsiden via knappen. ")])]],2)]:_vm._e()]},proxy:true},(_vm.payload.project.preview || _vm.payload.upcoming.preview)?{key:"actions",fn:function(){return [_c('Button',{attrs:{"label":_vm.payload.hasProject ? 'Forhåndsvis prosjektside' : 'Forhåndsvis kommer for salg side',"icon":"fa-pro-solid:arrow-up-right-from-square","theme":"primary","tag":"a","target":"_blank","href":_vm.payload.project.preview || _vm.payload.upcoming.preview}})]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }