<template>
  <div class="product-pricing-table">
    <DataTable
      class="pricing-table"
      :value="transformed"
      :columns="columns"
      theme="border"
      :sticky="false"
      :header="true"
    >
      <template #labels="{ item }">
        <span class="pricing-table__item--label">
          {{ item.label + generateStars(item.note) }}
        </span>
      </template>

      <template #broker="{ item }">
        <span :class="`pricing-table__item--${item.type}`">
          {{ item.type === 'price' ? toCurrency(item.broker) : item.broker }}
        </span>
      </template>

      <template #customer="{ item }">
        <span :class="`pricing-table__item--${item.type}`">
          {{ item.type === 'price' ? toCurrency(item.customer) : item.customer }}
        </span>
      </template>
    </DataTable>

    <template v-for="(n, i) in notes">
      <div :class="{ 'mt-1': i === 0 }">{{ generateStars(i + 1) + ' ' + n }}</div>
    </template>
    <Alert iconPlacement="left" class="mt-2">
      <template>
        <div class="bold">Automatisk fornyelse</div>
        <div>
          Dersom produktet ikke sies opp fornyes lisensprisen automatisk for ytterligere
          {{ this.priceBroker ? getPeriod(this.priceBroker.noticePeriod) : 'iht. avtale' }} mnd.
        </div>
        <div>Det er lisensprisen som videreføres, mens boligvelger og oppsett av mal er engangskostnader</div>
      </template>
    </Alert>
  </div>
</template>

<script>
import Card from '@kvass/card'
import DataTable from '@/components/DataTable.vue'
import { toCurrency } from '@/utils'
import { path } from 'ramda'
import { AlertComponent as Alert } from 'vue-elder-alert'

const formatCondition = ({ lte, gte }) => `${lte} - ${gte}`

const getPeriod = period => parseInt(period.slice(0, -1))
const getProjectPrice = v => path(['project', 'value'], v) || 0
const getTemplatePrice = v => path(['template', 'value'], v) || 0
const getAnglePrice = (v, angles) => {
  const anglePrice = path(['angle', 'value'], v) || 0
  if (typeof anglePrice === 'number') return anglePrice * angles
  return anglePrice
}
const getDomainPrice = v => path(['domain', 'value'], v) || 0

const createPriceRows = (broker, customer, angles, projectType, showFlatfinder, showDomain) => {
  if (!broker)
    return [
      { label: 'Pakke', broker: 'Skreddersøm', customer: 'Skreddersøm', type: 'badge' },
      { label: 'Antall enheter fra-til', broker: 'Skreddersøm', customer: 'Skreddersøm', type: 'text' },
      { label: 'Lisenspris pr mnd', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
      { label: 'Bindingstid antall mnd', broker: 'Iht. avtale', customer: 'Iht. avtale', type: 'text' },
      { label: 'Lisenspris i bindingstid', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
      { label: 'Oppsett boligvelger', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
      { label: 'Oppsett bruk av mal', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
      { label: 'Oppsett av eget nettsted', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
      { label: 'Sum', broker: 'Pris iht. avtale', customer: 'Pris iht. avtale', type: 'text' },
    ]

  const brokerProjectPriceInPeriod = getProjectPrice(broker) * getPeriod(broker.lockInPeriod)
  const customerProjectPriceInPeriod = getProjectPrice(customer) * getPeriod(customer.lockInPeriod)

  const brokerFlatfinderSetupPrice = showFlatfinder ? getAnglePrice(broker, angles) : 0
  const customerFlatfinderSetupPrice = showFlatfinder ? getAnglePrice(customer, angles) : 0

  const brokerTemplatePrice = getTemplatePrice(broker)
  const customerTemplatePrice = getTemplatePrice(customer)

  const brokerDomainPrice = showDomain ? getDomainPrice(broker) : 0
  const customerDomainPrice = showDomain ? getDomainPrice(customer) : 0

  const brokerTotal = brokerProjectPriceInPeriod + brokerFlatfinderSetupPrice + brokerTemplatePrice + brokerDomainPrice
  const customerTotal =
    customerProjectPriceInPeriod + customerFlatfinderSetupPrice + customerTemplatePrice + customerDomainPrice

  return [
    { label: 'Pakke', broker: broker.label, customer: customer.label, type: 'badge' },
    {
      label: 'Antall enheter fra-til',
      broker: formatCondition(broker.condition),
      customer: formatCondition(customer.condition),
      type: 'text',
    },
    {
      label: 'Lisenspris pr mnd',
      broker: getProjectPrice(broker),
      customer: getProjectPrice(customer),
      type: 'price',
    },
    {
      label: 'Bindingstid antall mnd',
      broker: getPeriod(broker.lockInPeriod),
      customer: getPeriod(customer.lockInPeriod),
      type: 'text',
    },
    {
      label: 'Lisenspris i bindingstid',
      broker: brokerProjectPriceInPeriod,
      customer: customerProjectPriceInPeriod,
      type: 'price',
      note: 1,
    },
    {
      label: 'Oppsett boligvelger',
      broker: brokerFlatfinderSetupPrice,
      customer: customerFlatfinderSetupPrice,
      type: 'price',
      note: 2,
      hide: !showFlatfinder,
    },
    {
      label: 'Oppsett bruk av mal',
      broker: brokerTemplatePrice,
      customer: customerTemplatePrice,
      type: 'price',
      note: 2,
    },
    {
      label: 'Oppsett av eget nettsted',
      broker: brokerDomainPrice,
      customer: customerDomainPrice,
      type: 'price',
      note: 3,
      hide: !showDomain,
    },
    {
      label: 'Sum',
      broker: brokerTotal,
      customer: customerTotal,
      type: 'price',
    },
  ].map(v => {
    const notRelevantLabels = ['Antall enheter fra-til', 'Pris boligvelger', 'Oppsett av eget nettsted']
    if ((!notRelevantLabels.includes(v.label) || projectType !== 'upcoming') && !v.hide) return v

    return {
      ...v,
      broker: 'Ikke relevant',
      customer: 'Ikke relevant',
      type: 'text',
    }
  })
}

export default {
  props: {
    priceBroker: Object,
    priceCustomer: Object,
    angles: Number,
    projectType: String,
    showFlatfinder: Boolean,
    showDomain: Boolean,
    costPriceConfig: String,
  },

  methods: {
    getPeriod,
    toCurrency,
    generateStars(n) {
      if (!n) return ''
      return '*'.repeat(n)
    },
  },

  computed: {
    notes() {
      return ['Kostnad ved fornyelse', 'Engangskostnad', 'Pr. påbegynte time']
    },

    isUpcoming() {
      return this.projectType === 'upcoming'
    },

    columns() {
      const generateLabel = v => `<b>${this.isUpcoming ? 'Kommer for salg' : 'Prosjektside'}</b></br><span>${v}</span>`

      return [
        { id: 'labels', label: 'Priser' },
        {
          id: 'broker',
          label: generateLabel('Kostpris eiendomsmelger'),
          condition: this.costPriceConfig !== 'customPricingLabel',
        },
        { id: 'customer', label: generateLabel('Kundepriser (anbefalt)') },
      ].filter(v => v.condition || !('condition' in v))
    },

    transformed() {
      return createPriceRows(
        this.priceBroker,
        this.priceCustomer,
        this.angles,
        this.projectType,
        this.showFlatfinder,
        this.showDomain,
      )
    },
  },

  components: {
    Card,
    DataTable,
    Alert,
  },
}
</script>

<style lang="scss">
.product-pricing-table {
  .pricing-table {
    .data-table__row:last-child {
      .data-table__cell {
        font-weight: bold;

        background-color: var(--light-grey);
      }
    }

    &__item {
      &--badge {
        padding: 4px 8px;
        border-radius: 30px;
        background-color: var(--light-grey);
      }

      &--text,
      &--price {
      }

      &--label {
      }
    }
  }
}
</style>
