<template>
  <div class="product-angles flex-row">
    <Button :label="String(value)" :disabled="disabled" :readonly="true" class="product-angles__display-button" />
    <div class="flex-col product-angles__actions">
      <Button
        label=""
        @click="add"
        :disabled="disabled"
        icon="fa-pro-solid:plus"
        class="product-angles__button--small"
      />
      <Button
        label=""
        @click="remove"
        :disabled="value <= 1 || disabled"
        icon="fa-pro-solid:minus"
        class="product-angles__button--small"
      />
    </div>
  </div>
</template>

<script>
import { ButtonComponent as Button } from 'vue-elder-button'

export default {
  props: {
    value: Number,
    disabled: Boolean,
  },

  methods: {
    add() {
      this.$emit('input', this.value + 1)
    },
    remove() {
      this.$emit('input', this.value - 1)
    },
  },

  components: {
    Button,
  },
}
</script>

<style lang="scss">
.product-angles {
  gap: 0.25rem;

  &__actions {
    justify-content: space-between;
  }

  &__display-button {
    pointer-events: none;
  }

  &__button {
    &--small {
      aspect-ratio: 1;
      font-size: 0.875rem;
      --vue-elder-button-padding-y: 0rem;
      --vue-elder-button-padding-x: 0.25rem;
    }
  }
}
</style>
