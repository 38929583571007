<template>
  <div class="billing__fields">
    <Input v-model="value.company" label="Bedriftsnavn" data-field="company" v-bind="fieldProps.company" />
    <Input
      v-model="value.organizationNumber"
      label="Org. nr."
      data-field="orgNumber"
      v-bind="fieldProps.organizationNumber"
    />
    <Input v-model="value.email" label="Epost" data-field="email" v-bind="fieldProps.email" />
    <Address
      v-if="showAddress"
      v-model="value.address"
      data-field="address"
      :validator="validator"
      validator-prefix="billing.address"
    />
  </div>
</template>

<script>
import { InputComponent as Input } from 'vue-elder-input'
import Address from '@/components/Address.vue'

export default {
  props: {
    showAddress: {
      type: Boolean,
      default: false,
    },
    value: Object,
    validator: Object,
    validatorPrefix: {
      type: String,
      default: 'billing',
    },
  },

  computed: {
    fieldProps() {
      if (!this.validator) return {}

      return Object.fromEntries(
        ['company', 'organizationNumber', 'email'].map(key => {
          const field = this.validator.fields[[this.validatorPrefix, key].join('.')]

          if (!field) return [key, {}]

          return [
            key,
            {
              required: field.rule.includes('required'),
              isValid: field.valid,
              validationMessage: field.error,
            },
          ]
        }),
      )
    },
  },

  components: {
    Input,
    Address,
  },
}
</script>

<style lang="scss">
.billing {
  &__fields {
    $cols: 2;

    @include respond-below('phone') {
      $cols: 1;
    }

    display: grid;
    grid-template-columns: repeat($cols, 1fr);
    grid-gap: 1rem;
    padding: 1rem;

    [data-field='address'],
    [data-field='email'] {
      grid-column-end: span $cols;
    }
  }
}
</style>
