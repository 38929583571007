<template>
  <div class="core-broker-admin">
    <div class="core-broker-admin__header flex-row">
      <h1>Administratoroversikt</h1>

      <Order v-model="value" :payload="payload" />
    </div>

    <div class="core-broker-admin__items grid-2 gap-4">
      <template v-if="payload.project.archived">
        <Deleted v-model="value" :payload="payload" class="core-broker-admin__deleted" />
      </template>
      <template v-else>
        <Settings v-model="value" :payload="payload" v-on="$listeners" />
        <Unpublish v-model="value" :payload="payload" />
      </template>
    </div>
  </div>
</template>

<script>
import Unpublish from '@/components/Broker/admin/unpublish.vue'
import Settings from '@/components/Broker/admin/settings.vue'
import Order from '@/components/Broker/admin/order.vue'
import Deleted from '@/components/Broker/admin/deleted.vue'

export default {
  props: {
    payload: Object,
    value: Object,
  },

  components: {
    Unpublish,
    Settings,
    Order,
    Deleted,
  },
}
</script>

<style lang="scss">
.core-broker-admin {
  .elder-button {
    @include respond-below('phone') {
      white-space: wrap !important;
    }
  }

  h1 {
    margin-bottom: 2rem;
    color: var(--dark-grey);
  }
  &__header {
    justify-content: space-between;
    align-items: start;
  }
  &__items {
    align-items: start;
  }

  &__deleted {
    grid-column: 1 / 3;
    justify-self: center;
  }
}
</style>
