import { ObjectToDotNotation, PrefixObjectKeys, hasDiff, isObject } from '@/utils'
import { mergeDeepRight, assocPath } from 'ramda'

export default function(opts = {}) {
  let {
    target,
    deep = false,
    storage = 'sessionStorage',
    key: rawKey,
    map = v => v,
    strategy = 'replace',
    disabled = () => false,
  } = opts
  if (!target) return console.log('[Storage]: No target specified -> Skipping...')
  if (!rawKey) return console.log('[Storage]: No key specified -> Skipping...')

  const Storage = Storages[storage]

  return {
    async mounted() {
      const key = await (async () => (typeof rawKey === 'string' ? rawKey : rawKey.call(this)))()

      const setTarget = () => {
        if (disabled.call(this) || !Storage.has.call(this, key)) return
        let preTarget = target.split('.').slice(0, -1),
          lastTarget = target.split('.').pop()

        switch (strategy) {
          case 'replace':
            return this.$set(this.$path(preTarget), lastTarget, map.call(this, Storage.get.call(this, key)))
          case 'merge':
            return this.$set(
              this.$path(preTarget),
              lastTarget,
              map.call(this, mergeDeepRight(this.$path(target), Storage.get.call(this, key))),
            )
        }
      }

      setTarget()

      if (Storage.onMounted) Storage.onMounted.call(this, setTarget)

      this.$watch(
        target,
        val => {
          if (disabled.call(this)) return
          Storage.set.call(this, key, val)
        },
        { deep },
      )
    },
  }
}

const StorageFactory = store => ({
  get(key) {
    if (!key) return

    try {
      return JSON.parse(window[store].getItem(key))
    } catch (err) {
      return window[store].getItem(key)
    }
  },
  set(key, value) {
    if (!key) return
    if ([null, undefined].includes(value)) return window[store].removeItem(key)

    return window[store].setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
  },
  has(key) {
    return window[store].getItem(key) !== null
  },
})

const Storages = {
  sessionStorage: StorageFactory('sessionStorage'),
  localStorage: StorageFactory('localStorage'),
  routerStorage: {
    get(key) {
      if (!key) return
      const isNested = Object.entries(this.$route.query).some(([k]) => k.startsWith(`${key}.`))
      const parse = v => {
        if ([null, undefined].includes(v)) return v
        switch (v.constructor) {
          case Array:
            v = (v instanceof Array ? v : [v]).map(e => decodeURIComponent(e))
            break
          case Boolean:
            if (v === 'true') v = true
            else if (v === 'false') v = false
            else v = null
            break
          default:
            v = decodeURIComponent(v)
        }

        return v
      }

      if (!isNested) return parse(this.$route.query[key])

      return Object.entries(this.$route.query)
        .filter(([k, v]) => k.startsWith(`${key}.`))
        .reduce((res, [k, v]) => {
          return assocPath(k.replace(new RegExp(`^${key}\.`), '').split('.'), parse(v), res)
        }, {})
    },
    set: function(key, value) {
      if (!key) return

      let data = isObject(value) ? PrefixObjectKeys(`${key}.`, ObjectToDotNotation(value)) : { [key]: value }

      data = Object.fromEntries(
        Object.entries(data).map(([k, v]) => {
          if ([null, undefined].includes(v)) return [k, undefined]
          switch (v.constructor) {
            case Array:
              return [k, v.map(e => encodeURIComponent(e))]
            default:
              return [k, encodeURIComponent(v)]
          }
        }),
      )

      let otherQueryParams = Object.fromEntries(
        Object.entries(this.$route.query).filter(([k]) => (isObject(value) ? !k.startsWith(`${key}.`) : k !== key)),
      )
      let newQuery = mergeDeepRight(otherQueryParams, data)
      if (!hasDiff(this.$route.query, newQuery)) return

      return this.$router.replace({ query: newQuery })
    },
    has(key) {
      return Object.entries(this.$route.query).some(([k]) => k === key || k.startsWith(`${key}.`))
    },
    onMounted(setTarget) {
      this.$watch('$route.query', setTarget, { deep: true })
    },
  },
}
