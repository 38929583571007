<template>
  <div
    v-bind="{
      class: classNames,
    }"
  >
    <template v-for="(item, index) in items">
      <label
        :for="`${_uid}_${index}`"
        class="radio-segment__radio"
        :class="{
          'radio-segment__radio--selected': isSelected(item),
          'radio-segment__radio--disabled': isDisabled,
        }"
      >
        <span class="radio-segment__label">
          {{ item.label }}
        </span>

        <Spinner v-if="isSelected(item) && isLoading" />
        <Icon
          v-if="isSelected(item) && showStateIndicator"
          class="radio-segment__state-indicator"
          :icon="stateIndicatorIcon"
        />

        <input
          type="radio"
          :value="item.value"
          :name="`radio-segment_${_uid}`"
          :disabled="isDisabled || isBusy"
          v-model="inputVal"
          :id="`${_uid}_${index}`"
          class="radio-segment__input"
          checked
        />
      </label>
    </template>
  </div>
</template>

<script>
import Icon from '@kvass/vue2-icon'
import Spinner from './Spinner.vue'

function isPromise(val) {
  if (!val || typeof val !== 'object') return

  return typeof val.then === 'function' && typeof val.catch === 'function'
}

function Capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1)
}

export default {
  props: {
    value: [String, Boolean, Number],
    items: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    promise: Promise,
    stateTimeout: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      state: null,
      innerPromise: null,
      stateTimer: null,
      loadingTreshhold: null,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },

    onState() {
      return this.state || this.isLoading
    },

    isLoading() {
      return this.state === 'loading'
    },

    isDisabled() {
      return this.isLoading || this.disabled
    },

    showStateIndicator() {
      return ['error', 'success'].includes(this.state)
    },

    stateIndicatorIcon() {
      return this.state === 'success' ? 'fa-pro-regular:check' : 'fa-pro-regular:exclamation-circle'
    },

    isBusy() {
      return ['success', 'error'].includes(this.state) || this.isLoading
    },

    classNames() {
      return [
        'radio-segment',
        `radio-segment--state-${this.state}`,
        {
          'radio-segment--loading': this.isLoading,
          'radio-segment--busy': this.isBusy,
        },
      ]
    },
  },

  methods: {
    isSelected(item) {
      return item.value === this.inputVal
    },

    hookPromise(promise) {
      if (!promise) return
      this.resetState()
      this.innerPromise = promise

      this.loadingTreshhold = setTimeout(() => (this.state = 'loading'), 100)

      let initStateTimeout = state => {
        this.resetState()
        if (this.stateTimeout) {
          this.state = state
          this.stateTimer = setTimeout(() => {
            this.resetState()
            this.$emit('on' + Capitalize(state))
          }, this.stateTimeout)
        } else this.$emit('on' + Capitalize(state))
      }

      this.innerPromise.then(() => initStateTimeout('success')).catch(() => initStateTimeout('error'))
    },
    resetState() {
      if (this.loadingTreshhold) clearTimeout(this.loadingTreshhold)
      if (this.stateTimer) clearTimeout(this.stateTimer)
      this.innerPromise = null
      this.state = null
    },
    onClick(event) {
      this.resetState()
      this.$emit('click', event)
    },
  },

  created() {
    this.$watch(
      function() {
        return this.promise
      },
      function(value) {
        if (isPromise(value)) this.hookPromise(value)
      },
    )
  },
  components: {
    Icon,
    Spinner,
  },
}
</script>

<style lang="scss">
.radio-segment {
  display: inline-flex;
  background-color: inherit;
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  border: 2px solid var(--border-color);

  &--state {
    &-error {
      .radio-segment__radio--selected {
        color: var(--error-contrast);
        background-color: var(--error);
      }
    }

    &-success {
      .radio-segment__radio--selected {
        color: var(--success-contrast);
        background-color: var(--success);
      }
    }
  }

  &:focus-within {
    outline: 2px solid var(--border-color);
    outline-offset: 2px;
  }

  &--loading {
    .radio-segment__radio {
      cursor: wait;
    }
  }

  &__label {
    transition: opacity 150ms ease, translate 150ms ease;
  }

  &--busy {
    .radio-segment__radio--selected .radio-segment__label {
      opacity: 0;
      translate: 0 0.25rem;
    }

    .radio-segment__radio {
      cursor: wait;
    }
  }

  &__input {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    position: absolute;
    padding: 0px;
    margin: -1px;
    overflow: hidden;
    white-space: nowrap;
  }

  .spinner,
  &__state-indicator {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 1em;
    aspect-ratio: 1;
  }

  &__radio {
    position: relative;
    user-select: none;
    cursor: pointer;
    padding: var(--spacing-sm) var(--spacing-default);
    white-space: nowrap;
    z-index: 1;

    &--disabled {
      cursor: not-allowed;

      &.radio-segment__radio--selected {
        color: #737373;
        background-color: #ccc;
      }
    }

    &--selected {
      background-color: var(--secondary);
      box-shadow: var(--box-shadow);
      border-radius: inherit;
    }
  }
}
</style>
