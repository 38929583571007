<template>
  <div class="billing-view">
    <div class="billing-view__item">
      <label class="billing-view__item__label">Bedriftsnavn</label>
      <div>{{ get('{company}') }}</div>
    </div>
    <div class="billing-view__item">
      <label class="billing-view__item__label">Org. nr.</label>
      <div>{{ get('{organizationNumber}') }}</div>
    </div>
    <div class="billing-view__item">
      <label class="billing-view__item__label">Epost</label>
      <div>{{ get('{email}') }}</div>
    </div>
  </div>
</template>

<script>
import { path } from 'ramda'

export default {
  props: {
    value: Object,
  },
  methods: {
    get(str) {
      return str.replace(/{([^{}]*)}/g, (a, b) => path([b], this.value))
    },
  },
}
</script>

<style lang="scss">
.billing-view {
  display: flex;
  gap: 2rem;
  padding: 1rem;

  @include respond-below('phone') {
    flex-direction: column;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &__label {
      font-size: 0.8em;
      opacity: 0.6;
    }
  }
}
</style>
