<template>
  <Card
    title="Prosjektet er avsluttet"
    subtitle="Prosjektet er avsluttet i Kvass."
    variant="prompt"
    class="admin-deleted"
  >
    <template #default>
      <div class="bold">
        Prosjektet {{ payload.project.name }} ble avsluttet av {{ payload.project.archivedBy.name }} ({{
          payload.project.archivedBy.email
        }})
        {{ payload.project.archivedAt }}
      </div>
    </template>
    <template #actions>
      <div>Ta kontakt med <a href="mailto:support@kvass.no">support@kvass.no</a> for hendvendelser</div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card-v2.vue'

export default {
  props: {
    payload: Object,
    value: Object,
  },

  computed: {},

  components: {
    Card,
  },
}
</script>

<style lang="scss"></style>
