<template>
  <span :class="`spinner spinner--size-${size}`"></span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },
}
</script>

<style lang="scss">
.spinner {
  &--size {
    &-small {
      width: 1px;
      border: 1px solid transparent;
      border-left-color: currentColor;
    }
    &-medium {
      width: 2px;
      border: 2px solid transparent;
      border-left-color: currentColor;
    }
    &-large {
      width: 4px;
      border: 4px solid transparent;
      border-left-color: currentColor;
    }
  }
  aspect-ratio: 1/1;
  border-radius: 999px;
  display: inline-block;
  opacity: 0.4;
  animation: spin 500ms linear infinite;
  position: relative;
  z-index: 1;

  @keyframes spin {
    to {
      rotate: 1turn;
    }
  }
}
</style>
