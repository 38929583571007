export default function SSE(response) {
  const decoder = new TextDecoder()
  const listeners = []

  const Parse = chunk => {
    return decoder
      .decode(chunk)
      .split('\n')
      .map(v => {
        if (!v) return
        return JSON.parse(v.replace('data: ', ''))
      })
      .filter(Boolean)
  }

  const reader = response.body.getReader()

  const emit = (event, payload) => listeners.filter(l => l.event === event).forEach(l => l.callback(payload))

  reader.read().then(function Consume({ done, value }) {
    if (done) return emit('finished')

    Parse(value).forEach(e => emit(e.event, e.payload))

    return reader.read().then(Consume)
  })

  return {
    on: (event, callback) => {
      listeners.push({ event, callback })
    },
    cancel: () => {
      reader.cancel()
    },
  }
}
