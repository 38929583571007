<template>
  <Card
    title="Er prosjektet ferdig?"
    subtitle="Ønsker du å slette prosjektnettsiden med tilhørende informasjon?"
    variant="prompt"
    class="admin-unpublish"
  >
    <template #default>
      <div class="bold mb-2">Slett {{ type }}</div>
      <Checkbox
        v-model="unpublish"
        :label="`Ja, jeg ønsker å slette ${type} med tilhørende informasjon`"
        :disabled="isDisabled"
      />

      <Alert iconPlacement="left" class="mt-4">
        <template>
          <div class="bold">Prosjektet slettes fra Kvass umiddelbart</div>
          <div>Sletting påvirker</div>
          <ul class="mt-0">
            <li>
              Prosjektet på
              <a target="_blank" :href="payload.homepage.url" class="bold">{{ payload.homepage.label }}</a> nettside.
            </li>
          </ul>
          <div>Faktura for lisens vil løpe ut bindingstid.</div>
        </template>
      </Alert>
    </template>

    <template #actions>
      <Button
        :label="isDisabled ? 'Sletting fullført' : `Bekreft sletting av ${type}`"
        theme="primary"
        :disabled="!validator.passes || hasDeleted"
        @click="remove()"
        :promise="promise"
        :labels="{
          success: 'Fullført',
          loading: 'Sletter...',
          error: 'Noe gikk galt',
        }"
        @onSuccess="() => (hasDeleted = true)"
      />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card-v2.vue'
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'
import { CheckboxComponent as Checkbox } from 'vue-elder-checkbox'
import fetch from '@/api/fetch'
import Validator from '@/mixins/validator-v2'

export default {
  inject: ['$token'],

  mixins: [
    Validator({
      data: 'dataComp',
      rules: {
        unpublish: 'accepted',
      },
    }),
  ],
  props: {
    payload: Object,
    value: Object,
  },

  data() {
    return {
      hasDeleted: false,
      unpublish: false,
      promise: null,
    }
  },

  computed: {
    isDisabled() {
      return (
        this.hasDeleted || this.payload.project.archived || (!this.payload.hasProject && this.payload.upcoming.archived)
      )
    },
    token() {
      return this.$token()
    },
    dataComp() {
      return {
        unpublish: this.unpublish,
      }
    },

    type() {
      return this.payload.hasProject ? 'prosjektside' : 'kommer-for-salg side'
    },
  },

  methods: {
    remove() {
      const { tenant, integrationId } = this.payload
      const url = `https://${tenant}/api/integration/${integrationId}/callbacks/unpublish?token=${this.token}&caseId=${this.payload.caseId}`

      this.promise = fetch(url, { method: 'POST' })
    },
  },
  components: {
    Button,
    Card,
    Alert,
    Checkbox,
  },
}
</script>

<style lang="scss">
.admin-unpublish {
  .alert__content {
    align-items: start;
  }
  .alert__icon {
    margin-top: 0.2rem;
  }
}
</style>
