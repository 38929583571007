<template>
  <div
    :class="[
      `data-table data-table--${theme}`,
      { 'data-table--sticky': Boolean(sticky), 'data-table--no-header': !header },
    ]"
    :style="`--data-table-layout: ${gridLayout}; --data-table-sticky-top: ${typeof sticky === 'string' ? sticky : '0'}`"
  >
    <div class="data-table__row" v-if="header">
      <div class="data-table__cell data-table__cell-select" v-if="isSelectable">
        <CheckboxComponent
          class="data-table__checkbox"
          v-model="selectAll"
          :disabled="!valueComp.length"
          :indeterminate="Boolean(selected.length && selected.length < valueComp.length)"
        />
      </div>
      <div
        :class="[
          'data-table__cell',
          `data-table__cell--text-align-${col.align || 'start'}`,
          {
            'data-table__cell-sort': isSortable && sortableBy.includes(col.id),
            'data-table__cell-sort--active': col.id in (sortBy || {}),
          },
        ]"
        :key="col.id"
        v-for="col in columns.filter(({ disabled }) => !disabled)"
        @click="sort(col.id)"
      >
        <slot :name="`${col.id}-label`" :item="col">
          <span v-html="col.label"></span>
        </slot>
        <FontAwesomeIcon
          class="data-table__cell-sort-icon"
          :icon="['fas', (sortBy || {})[col.id] < 0 ? 'caret-up' : 'caret-down']"
        />
      </div>
      <div v-if="hasRowActions" class="data-table__cell data-table__cell-actions"></div>
    </div>
    <div class="data-table__row" v-for="(item, index) in value" :key="index">
      <div class="data-table__cell data-table__cell-select" v-if="isSelectable">
        <CheckboxComponent
          class="data-table__checkbox"
          @input="$ev => $emit(`update:selected`, $ev)"
          :value="selected"
          :state="item"
          @click.native="$ev => onCheckboxClick($ev, index)"
          @mousedown.prevent.native
          :disabled="isDisabled(item)"
        />
      </div>
      <component
        :is="rowWrapper(item).component"
        class="data-table__row-wrapper"
        v-bind="rowWrapper(item).props"
        v-on="rowWrapper(item).listeners"
      >
        <component
          :is="cellWrapper(item, col).component"
          v-bind="cellWrapper(item, col).props"
          v-on="cellWrapper(item, col).listeners"
          :class="`data-table__cell data-table__cell--text-align-${col.align || 'start'}`"
          v-for="(col, colIndex) in columns.filter(({ disabled }) => !disabled)"
          :key="`col-${col.id}`"
        >
          <slot :name="col.id" :item="item" :column="col" :row-index="index" :col-index="colIndex" />
        </component>
      </component>
      <div v-if="hasRowActions" class="data-table__cell data-table__cell-actions">
        <ButtonGroup>
          <slot name="primary-row-actions" :item="item" />
          <template #secondary="{ visible }">
            <slot name="secondary-row-actions" :item="item" :visible="visible" />
          </template>
        </ButtonGroup>
      </div>
    </div>
    <!-- <transition name="footer">
      <div v-if="selected && selected.length" class="data-table__footer">
        <DropdownComponent v-if="selected.length">
          <ButtonComponent :label="`${$t('selection')} (${selected.length})`" :icon="['fal', 'ellipsis-v']" />
          <template #dropdown>
            <slot name="select-actions" />
            <ButtonComponent
              :label="$t('resetSelection')"
              :icon="['fal', 'times-square']"
              @click="$emit('update:selected', [])"
            />
          </template>
        </DropdownComponent>
        <slot name="footer" />
        <span>
          <FontAwesomeIcon :icon="['fal', 'info-circle']" style="margin-right: 0.2rem" />
          <i18n path="tableFooterTip" tag="span">
            <Keyboard value="shift" />
          </i18n>
        </span>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { CheckboxComponent } from 'vue-elder-checkbox'
import { DropdownComponent } from 'vue-elder-dropdown'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import Keyboard from '@/components/Keyboard'
// import ButtonGroup from '@/components/ButtonGroup'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
      validator(val) {
        return ['default', 'border'].includes(val)
      },
    },
    sticky: [Boolean, String],
    sortBy: Object,
    sortableBy: {
      type: Array,
      default: () => [],
    },
    sortTransform: {
      type: Function,
      default: (item, value) => ({ [item.key]: value }),
    },
    cellWrapper: {
      type: Function,
      default: () => ({ component: 'div' }),
    },
    rowWrapper: {
      type: Function,
      default: () => ({ component: 'div' }),
    },
    selected: Array,
    header: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      lastSelectedIndex: [-1],
    }
  },
  computed: {
    valueComp() {
      return this.value.filter(v => !this.isDisabled(v))
    },
    isSelectable() {
      return Boolean(this.$options.propsData.selected)
    },
    isSortable() {
      return Boolean(this.$options.propsData.sortBy) && this.sortableBy.length
    },
    hasRowActions() {
      return ['primary-row-actions', 'secondary-row-actions'].some(k => this.$scopedSlots[k])
    },
    selectAll: {
      get() {
        return Boolean(this.valueComp.length && this.valueComp.every(s => this.selected.includes(s)))
      },
      set(val) {
        return this.$emit('update:selected', val ? [...this.valueComp] : [])
      },
    },
    gridLayout() {
      return [
        this.isSelectable ? '2.5rem' : false,
        ...this.columns.filter(({ disabled }) => !disabled).map(c => c.size || 'auto'),
        this.hasRowActions ? '0px' : false,
      ]
        .filter(Boolean)
        .join(' ')
    },
  },
  methods: {
    sort(id) {
      if (!this.isSortable || !this.sortableBy.includes(id)) return

      let existingSort = (this.sortBy && this.sortBy[id]) || -1
      this.$emit('update:sortBy', { [id]: existingSort * -1 })
    },
    onCheckboxClick(event, index) {
      let target = this.lastSelectedIndex.slice(-1).pop()
      let items = []

      if (event.shiftKey) {
        let reversed = target > index
        let start = target + (reversed ? -1 : 1)

        while (reversed ? start > index : start < index) {
          let target = this.value[start]
          if (!this.selected.includes(target) && !this.isDisabled(target)) items.push(target)
          reversed ? start-- : start++
        }
      }

      if (event.target.checked) this.lastSelectedIndex.push(index)
      else if (target === index) this.lastSelectedIndex.pop()

      this.$emit('update:selected', [...this.selected, ...items])
    },
  },
  components: {
    CheckboxComponent,
    DropdownComponent,
    // Keyboard,
    // ButtonGroup,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.data-table {
  width: 100%;
  display: grid;
  position: relative;

  grid-template-columns: var(--data-table-layout);

  &--sticky {
    .data-table__row:first-child .data-table__cell {
      z-index: 2;
      position: sticky;
      top: var(--data-table-sticky-top);
      border-bottom: 1px solid var(--border-color);
    }
  }

  &:not(.data-table--no-header) {
    .data-table__row:first-child {
      background-color: white;
      font-size: 0.8em;
      text-align: left;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    padding: 0.75rem;

    &--text-align {
      &-start {
        justify-content: start;
      }
      &-center {
        justify-content: center;
      }
      &-end {
        justify-content: end;
      }
    }

    &-sort {
      cursor: pointer;
      vertical-align: middle;

      &-icon {
        opacity: 0;
        margin-left: 0.25rem;
      }

      &:not(.data-table__cell-sort--active):hover {
        .data-table__cell-sort-icon {
          opacity: 0.3;
        }
      }

      &--active {
        .data-table__cell-sort-icon {
          opacity: 1;
        }
      }
    }

    &-actions {
      padding: 0;
      position: relative;
    }
  }

  &__row {
    display: contents;

    &:hover,
    &:focus-within {
      .data-table__cell-actions .button-group {
        display: flex;
      }
    }

    &-wrapper {
      display: contents;
    }

    .data-table--default & {
      &:nth-child(odd) .data-table__cell {
        background-color: css-lighten('border-color', 8%);
      }
    }

    .data-table--border & {
      &:not(:last-child) .data-table__cell {
        border-bottom: 1px solid css-alpha('border-color', 0.5);
      }

      &:first-child {
        .data-table__cell {
          background-color: inherit;
        }
      }
    }

    .button-group {
      display: none;
      align-items: center;
      position: absolute;
      right: 0;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      margin: 0.5rem;
      animation: fadeInFromLeft 200ms ease;

      .elder-button > * {
        padding: 0.3rem 0.75rem !important;
      }
    }
  }

  &__checkbox {
    &:not(.elder-checkbox--checked):not(.elder-checkbox--indeterminate) .elder-checkbox__box {
      border-color: var(--border-color);
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    grid-column: 1 / -1;
    font-size: var(--font-size-small);

    background-color: white;
    animation: fadeInUp 200ms ease-out;

    border-top: 1px solid var(--border-color);

    .data-table--default & {
      background-color: whitesmoke;
    }

    .keyboard {
      margin-inline: 0.25rem;
    }
  }
}

.footer-enter-active,
.footer-leave-active {
  transition: 200ms ease-out;
}

.footer-enter-from,
.footer-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
