<template>
  <component :is="tag" v-bind="binding" v-on="$listeners" class="card-v2">
    <img v-if="thumbnail" :src="thumbnail" class="card-v2__thumbnail" />
    <header v-if="hasHeader" class="card-v2__header">
      <slot name="header">
        <div v-if="title" class="card-v2__title">{{ title }}</div>
        <div v-if="title && subtitle" class="card-v2__subtitle">{{ subtitle }}</div>
      </slot>
    </header>
    <div v-if="hasContent" class="card-v2__content">
      <slot />
    </div>
    <div v-if="hasActions" class="card-v2__actions">
      <slot name="actions" />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    thumbnail: String,
    title: String,
    subtitle: String,
    variant: {
      type: String,
      enums: ['widget', 'prompt', 'default', 'horizontal'],
      default: 'default',
    },
  },
  computed: {
    binding() {
      return {
        ...this.$attrs,
        class: ['card-v2', 'card-v2--' + this.variant],
      }
    },
    hasHeader() {
      return this.title || this.$slots.header
    },
    hasActions() {
      return this.$slots.actions
    },
    hasContent() {
      return this.$slots.default
    },
  },
  components: {},
}
</script>

<style lang="scss">
.card-v2 {
  display: grid;

  border-radius: var(--border-radius-md);
  background-color: white;
  text-decoration: none;
  color: inherit;
  box-shadow: var(--shadow-lg);

  overflow: clip;

  // Replace box-shadow with border on nested
  .card-v2 {
    box-shadow: none;
    border: 2px solid var(--light-grey);

    .card-v2__title {
      @include typography('h3');
    }
  }

  &--default {
    grid-template-columns: 1fr;
    grid-template-areas: 'thumbnail' 'header' 'content' 'actions';

    & > .card-v2__content {
      --card-padding: var(--spacing-lg);
      padding: 0 var(--card-padding) var(--card-padding);
    }

    & > .card-v2__actions {
      padding: var(--spacing-lg);
      border-top: 2px solid var(--light-grey);

      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-default);
    }
  }

  &--horizontal {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'thumbnail header' 'thumbnail content' 'thumbnail actions';

    @include respond-below('phone') {
      grid-template-columns: 1fr;
      grid-template-areas: 'thumbnail' 'header' 'content' 'actions';
    }

    & > .card-v2__thumbnail {
      align-self: stretch;
    }

    & > .card-v2__content {
      --card-padding: var(--spacing-lg);
      padding: 0 var(--card-padding) var(--card-padding);
    }

    & > .card-v2__actions {
      padding: var(--spacing-lg);
      border-top: 2px solid var(--light-grey);

      background-color: var(--lightest-grey);

      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-default);
    }
  }

  &--prompt {
    grid-template-columns: 1fr;
    grid-template-areas: 'thumbnail' 'header' 'content' 'actions';

    & > .card-v2__header {
      background-color: var(--lightest-grey);
    }

    & > .card-v2__content {
      --card-padding: var(--spacing-lg);
      padding: var(--card-padding);
    }

    & > .card-v2__actions {
      background-color: var(--lightest-grey);

      padding: var(--spacing-lg);

      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-default);
    }
  }

  &--widget {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'thumbnail thumbnail' 'header actions' 'content content';

    & > .card-v2__content {
      --card-padding: var(--spacing-lg);
      padding: var(--card-padding);
      border-top: 2px solid var(--lightest-grey);
    }

    & > .card-v2__actions {
      padding: var(--spacing-lg);

      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-default);
    }
  }

  &__thumbnail {
    grid-area: thumbnail;
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  & > &__header {
    grid-area: header;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-lg);
  }

  & > &__content {
    grid-area: content;
  }

  & > &__actions {
    grid-area: actions;
  }

  &__title {
    margin-block-end: 0;

    @include typography('h2');
  }

  &__subtitle {
    margin-block: 0;

    @include typography('body');
  }
}
</style>
