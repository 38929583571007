<template>
  <div class="subscription-lines">
    <div v-if="title" class="subscription-line subscription-line--header">
      <div data-part="description">{{ typeof title === 'string' ? title : value.title }}</div>
    </div>
    <div class="subscription-line" v-for="line in value.lines">
      <div data-part="description">
        {{ line.description }} <span v-if="line.comment">({{ line.comment }})</span>
      </div>
      <div data-part="value">{{ toCurrency(line.value) }}</div>
      <div data-part="suffix">{{ getSuffix(line) }}</div>
    </div>
  </div>
</template>

<script>
import { toCurrency } from '@/utils'

const getTotal = lines => {
  const result = lines.reduce(
    (res, line) => {
      if (typeof line.value === 'number') res[line.type] += line.value
      else if (typeof line.value === 'string') res[line.type] = line.value

      return res
    },
    { fixed: 0, monthly: 0, hourly: 0 },
  )

  return [
    { type: 'fixed', value: result.fixed },
    { type: 'monthly', value: result.monthly },
    { type: 'hourly', value: result.hourly },
  ].filter(({ value }) => value)
}

const getSuffix = line => {
  switch (line.type) {
    case 'hourly':
      return `/ time`
    case 'monthly':
      return `/ mnd`
    default:
      return ''
  }
}

export { getTotal, getSuffix }

export default {
  props: {
    value: Object,
    title: [String, Boolean],
  },
  methods: {
    getSuffix,
    toCurrency,
  },
}
</script>

<style lang="scss">
.subscription-lines {
  display: grid;
  grid-template-columns: 1fr auto auto;

  --line-space: 0.6em;

  & + & {
    margin-top: var(--spacing-xl);
  }

  .subscription-line {
    display: contents;

    [data-part='description'] {
      padding-right: 2em;

      span {
        margin-left: var(--spacing-xs);
        color: var(--dark-grey);
      }
    }

    [data-part='value'],
    [data-part='suffix'] {
      text-align: right;
      vertical-align: baseline;
    }

    [data-part='suffix'] {
      padding-left: 0.25em;
      align-self: flex-end;
    }

    &--footer {
      font-weight: bold;

      [data-part='value'] {
        grid-column-end: span 2;
      }

      & + & [data-part='value']:before {
        content: '+ ';
      }
    }

    &--header {
      font-weight: bold;

      [data-part] {
        padding-bottom: var(--line-space);
        margin-bottom: var(--line-space);
        border-bottom: 1px solid var(--light-grey);
        grid-column: 1 / -1;
      }
    }
  }
}
</style>
