import { mergeDeepRight } from 'ramda'

export default async function(url, options = {}) {
  let defaultOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  return fetch(url, mergeDeepRight(defaultOptions, options)).then(res => {
    if (!res.ok) throw res
    return res
  })
}
