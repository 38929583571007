<template>
  <div class="address">
    <Input
      v-model="value.street"
      label="Gateadresse"
      :disabled="disabled"
      v-bind="fieldProps.street"
      data-field="street"
    />
    <Input
      v-model="value.postcode"
      label="Postnummer"
      data-field="postcode"
      :disabled="disabled"
      v-bind="fieldProps.postcode"
    />
    <Input v-model="value.city" label="Sted" :disabled="disabled" data-field="city" v-bind="fieldProps.city" />
    <Input v-model="value.county" label="Fylke" :disabled="disabled" data-field="county" v-bind="fieldProps.county" />
  </div>
</template>

<script>
import { InputComponent as Input } from 'vue-elder-input'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    disabled: Boolean,
    validator: Object,
    validatorPrefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    fieldProps() {
      if (!this.validator) return {}

      return Object.fromEntries(
        ['street', 'city', 'postcode', 'county'].map(key => {
          const field = this.validator.fields[[this.validatorPrefix, key].join('.')]

          if (!field) return [key, {}]

          return [
            key,
            {
              required: field.rule.includes('required'),
              isValid: field.valid,
              validationMessage: field.error,
            },
          ]
        }),
      )
    },
  },

  components: {
    Input,
  },
}
</script>

<style lang="scss">
.address {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  [data-field='street'],
  [data-field='county'] {
    grid-column-end: span 2;
  }

  [data-field='postcode'],
  [data-field='city'] {
    grid-column-end: span 1;
  }
}
</style>
