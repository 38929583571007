var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"product-flatfinder-setup"},'div',_vm.$attrs,false),[_c('Card',{staticClass:"core-broker-step-products__card",class:{ 'core-broker-step-products__card--selected': _vm.item.isSelected },attrs:{"thumbnail":_vm.item.image,"title":_vm.item.title,"variant":"horizontal"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.description)}})]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"bold",domProps:{"innerHTML":_vm._s(_vm.item.price)}}),_c('div',{staticClass:"flex-row gap-1 flex-wrap"},[_c('Angles',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltipProps(_vm.item)),expression:"getTooltipProps(item)"}],attrs:{"disabled":_vm.item.disabled},on:{"input":function () { return (_vm.item.isSelected ? {} : _vm.setFlatfinderSetup(_vm.item.value)); }},model:{value:(_vm.value.flatfinder.angles),callback:function ($$v) {_vm.$set(_vm.value.flatfinder, "angles", $$v)},expression:"value.flatfinder.angles"}}),_c('Button',_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltipProps(_vm.item)),expression:"getTooltipProps(item)"}],class:{ 'elder-button--disabled': _vm.item.disabled },on:{"click":function () {
              if (_vm.item.disabled) { return }
              return (_vm.show = true)
            }}},'Button',_vm.getButtonProps(_vm.item.isSelected),false))],1),_c('Modal',{attrs:{"show":_vm.show,"clickaway":true},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var close = ref.close;
return [_c('Card',{staticClass:"product-flatfinder-setup__card",attrs:{"title":"Dette trenger vi for å lage boligvelger","variant":"prompt"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_vm._v(" Vi trenger først et 3D bilde av prosjektet, deretter en markering av hvilke enheter som hører til hvor. Markering kan fint tegnes av melger med for eksempel Power-point. ")]),_c('div',{staticClass:"grid-2 gap-4"},[_vm._l((_vm.examples),function(i){return [_c('div',{staticClass:"gap-1 flex-col"},[_c('b',[_vm._v(_vm._s(i.title))]),_c('img',{style:({ width: '100%', 'aspect-ratio': 4 / 3 }),attrs:{"src":i.image}})])]})],2),_c('Card',{attrs:{"title":"Last opp 2 eller flere filer til boligvelger","variant":"widget"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('File',{attrs:{"upload":_vm.upload,"label":"","multiple":true,"accept":"image/*"},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
            var item = ref.item;
return [_c('img',{attrs:{"src":item.url,"alt":item.name}})]}}],null,true),model:{value:(_vm.value.flatfinder.images),callback:function ($$v) {_vm.$set(_vm.value.flatfinder, "images", $$v)},expression:"value.flatfinder.images"}})]},proxy:true}],null,true)}),_c('Input',{attrs:{"label":"Valgfri kommentar"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.flatfinder.comment),expression:"value.flatfinder.comment"}],staticClass:"elder-input__element",attrs:{"rows":"2","placeholder":"Om du ønsker å fortelle noe ekstra, skriv det gjerne her."},domProps:{"value":(_vm.value.flatfinder.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.flatfinder, "comment", $event.target.value)}}})])]},proxy:true},{key:"actions",fn:function(){return [_c('Button',{attrs:{"label":"Avbryt"},on:{"click":close}}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.validatorErrors,
                  placement: 'right',
                  delay: 0,
                  offet: 20,
                  classes: ['core-broker-step-products__tooltip'],
                }),expression:"{\n                  content: validatorErrors,\n                  placement: 'right',\n                  delay: 0,\n                  offet: 20,\n                  classes: ['core-broker-step-products__tooltip'],\n                }"}],class:{ 'elder-button--disabled': !_vm.validator.passes },attrs:{"label":"Bekreft","theme":"primary"},on:{"click":_vm.submit}})]},proxy:true}],null,true)})]}}])})]},proxy:true}])}),_c('Alert',{staticClass:"mt-2",attrs:{"iconPlacement":"left"}},[[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.alert)}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }